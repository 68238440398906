import {createActivityZone, updateActivityZone} from "@/api/$platform";
import {Message} from "element-ui";
import {Subject} from "rxjs";

export class ZoneEditor {
  refresh = new Subject();

  title = '';
  display = false;
  isEditing = false;
  form = {
    id: undefined,
    category_name: '',
    showtype: '',
    sort: '',
  };
  show_types = [
    {
      label: '全景',
      value: 'full'
    },
    {
      label: '全景卡片',
      value: 'full-card'
    },
    {
      label: '列表',
      value: 'list-item'
    },
    // {
    //   label: '小卡片',
    //   value: 'small-card'
    // },
  ]

  resetForm(form) {
    this.title = form ? '专区编辑' : '新建专区';
    this.isEditing = !!form;
    this.form = {
      category_name: '',
      showtype: '',
      ...form
    };
  }

  show(form) {
    this.display = true;
    this.resetForm(form);
  }

  hide() {
    this.display = false;
  }

  submit() {
    if (this.isEditing) {
      updateActivityZone({
        id: this.form.id,
        name: this.form.category_name,
        // showtype: this.form.showtype,
      }).then(resp => {
        Message.success('修改成功');
        this.hide();
        this.refresh.next();
      });
    } else {
      createActivityZone({
        category_name: this.form.category_name,
        showable: 0,
        showtype: this.form.showtype,
      }).then(resp => {
        Message.success('创建成功');
        this.hide();
        this.refresh.next();
      })
    }
  }
}
