<template>
  <el-card class="page-activity-zone-list">
    <div slot="header" class="row align-items-center">
      <div class="col">
        <b>专区管理</b>
      </div>
      <div class="col-auto">
        <el-button type="primary" @click="editor.show()">添加专区</el-button>
      </div>
    </div>

    <el-table :data="data" border style="width: 100%">
      <el-table-column prop="sort" label="序号" width="80">
        <template slot-scope="scope">
          <div class="row no-gutters align-items-center data-sort">
            <div class="col">{{ scope.$index + 1 }}</div>
            <div class="col-auto default-hidden">
              <el-tooltip effect="dark" content="下移" placement="top">
                <i class="btn el-icon-bottom" @click="moveDown(scope.row)" :class="{
          'cursor-pointer':
            scope.$index >= 1 && scope.$index < data.length - 1,
        }" />
              </el-tooltip>
            </div>
            <div class="col-auto default-hidden">
              <el-tooltip effect="dark" content="上移" placement="top">
                <i class="btn el-icon-top" @click="moveUp(scope.row)" :class="{ 'cursor-pointer': scope.$index > 1 }" />
              </el-tooltip>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="category_name" label="专区名称"></el-table-column>
      <!--      <el-table-column label="显示类型" width="180">-->
      <!--        <template slot-scope="scope">-->
      <!--          {{ getDisplayText(scope.row) }}-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column label="是否显示" width="180">
        <template slot-scope="scope">
          <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.showable"
            @change="setActivityZoneDisplayable(scope.row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="排序">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="280">
        <template slot-scope="scope">
          <el-button v-if="!scope.row.category_name.includes('精选')" type="text" @click="editor.show(scope.row)"
            :style="{ color: '#409EFF' }">
            修改
          </el-button>
          <el-popconfirm title="确定删除该分区吗？" @onConfirm="deleteZone(scope.row)"
            v-if="!['精选', '资讯专区'].includes(scope.row.category_name)">
            <el-button slot="reference" type="text" :style="{ color: '#DB4F2E' }">
              删除
            </el-button>
          </el-popconfirm>
          <el-button v-if="scope.$index > 0 && scope.$index < data.length - 1" type="text"
            @click="moveDown(scope.row)">下移
          </el-button>
          <el-button v-if="scope.$index > 0" type="text" @click="moveUp(scope.row)">上移
          </el-button>
          <el-button type="text" @click="toZoneDetail(scope.row)">专区内容管理
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="editor.title" :visible.sync="editor.display" width="400px">
      <el-form :model="editor.form" :label-position="'left'">
        <!-- <el-form-item label="专区名称">
          <el-input
            v-model="editor.form.category_name"
            :disabled="editor.form.sort === 2"
            style="width: 260px"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="专区名称">
          <el-select v-model="editor.form.category_name" placeholder="请选择专区" style="width: 260px">
            <el-option v-for="item in categoryList" :key="item.category_id" :label="item.name"
              :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item label="显示类型">-->
        <!--          <el-select v-model="editor.form.showtype" placeholder="请选择专区显示类型" style="width: 260px">-->
        <!--            <el-option-->
        <!--              v-for="item in editor.show_types"-->
        <!--              :key="item.value"-->
        <!--              :label="item.label"-->
        <!--              :value="item.value"-->
        <!--            ></el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editor.hide()">取 消</el-button>
        <el-button type="primary" @click="editor.submit()">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script lang="js">
import ActivityZoneListPage from './activity-zone-list';

export default ActivityZoneListPage;
</script>

<style lang="scss">
@import "./activity-zone-list";
</style>
