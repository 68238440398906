import {Component, Vue} from 'vue-property-decorator';
import {deleteActivityZone, listActivityZones, setActivityZoneDisplayable, setActivityZoneSort, listCategory} from "@/api/$platform";
import {ZoneEditor} from "./zone-editor";
import {$$router} from "@/libs/router/router";

export default @Component
class ActivityZoneListPage extends Vue {
  data = [];
  editor = new ZoneEditor();
  categoryList = [];

  mounted() {
    this.load();
    this.editor.refresh.subscribe(() => {
      this.load();
    });
  }

  refresh() {
    this.load();
  }

  load() {
    listActivityZones({page_no: 1, page_size: 100}).then(resp => {
      resp.data = resp.data.sort((a, b) => {
        return a.sort - b.sort;
      });
      this.data = resp.data;
    });
    listCategory({parentId: 0}).then(res => {
      this.categoryList = res
    })
  }

  setActivityZoneDisplayable(row) {
    setActivityZoneDisplayable({id: row.id, showable: row.showable }).then(resp => {
      this.load();
      if (row.showable === 0) {
        this.$message.warning(`专区已隐藏`);
      } else {
        this.$message.success(`专区已显示`);
      }
    });
  }

  getDisplayText(row) {
    return {'full-card': '全景卡片', 'full': '全景', 'list-item': '列表', 'small-card': '小卡片'}[row.showtype];
  }

  moveDown(zone) {
    setActivityZoneSort({
      id: zone.id,
      type: 'down',
      sort: zone.sort,
    }).then(resp => {
      this.load();
    });
  }

  moveUp(zone) {
    setActivityZoneSort({
      id: zone.id,
      type: 'up',
      sort: zone.sort,
    }).then(resp => {
      this.load();
    });
  }

  deleteZone(zone) {
    deleteActivityZone({
      id: zone.id,
    }).then(resp => {
      console.log(resp);
      this.$message.error('删除成功！');
      this.load();
    })
  }

  toZoneDetail(zone) {
    return $$router.get().forward('专区详情', {id: zone.category_id});
  }
}
